exports.components = {
  "component---src-pages-affiliates-js": () => import("./../../../src/pages/affiliates.js" /* webpackChunkName: "component---src-pages-affiliates-js" */),
  "component---src-pages-agency-js": () => import("./../../../src/pages/agency.js" /* webpackChunkName: "component---src-pages-agency-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-demo-booking-js": () => import("./../../../src/pages/demo/booking.js" /* webpackChunkName: "component---src-pages-demo-booking-js" */),
  "component---src-pages-demo-ecommerce-js": () => import("./../../../src/pages/demo/ecommerce.js" /* webpackChunkName: "component---src-pages-demo-ecommerce-js" */),
  "component---src-pages-demo-real-estate-js": () => import("./../../../src/pages/demo/real-estate.js" /* webpackChunkName: "component---src-pages-demo-real-estate-js" */),
  "component---src-pages-dyncomparator-js": () => import("./../../../src/pages/dyncomparator.js" /* webpackChunkName: "component---src-pages-dyncomparator-js" */),
  "component---src-pages-features-js": () => import("./../../../src/pages/features.js" /* webpackChunkName: "component---src-pages-features-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-roboassist-js": () => import("./../../../src/pages/roboassist.js" /* webpackChunkName: "component---src-pages-roboassist-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-testpage-contact-js": () => import("./../../../src/pages/testpage/contact.js" /* webpackChunkName: "component---src-pages-testpage-contact-js" */),
  "component---src-pages-testpage-index-js": () => import("./../../../src/pages/testpage/index.js" /* webpackChunkName: "component---src-pages-testpage-index-js" */),
  "component---src-pages-testpage-pricing-js": () => import("./../../../src/pages/testpage/pricing.js" /* webpackChunkName: "component---src-pages-testpage-pricing-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-comparator-js": () => import("./../../../src/templates/comparator.js" /* webpackChunkName: "component---src-templates-comparator-js" */)
}

