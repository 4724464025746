import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "popper.js/dist/popper.min";
import "bootstrap/dist/js/bootstrap.min.js";
// import Hotjar from '@hotjar/browser';


export const onClientEntry = () => {
/*
    const siteId = 4975986;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);
*/
};
